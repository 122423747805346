<template>
  <div class="card shadow text-left pad-10">
    <span
      class="col-lg-12 d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary card-title"
    >Data Transaksi Hotel</span>
    <div class="mt-2">
      <!-- <div class="purchases-list">
        <div class="purchases-list-header">
          <div class="purchases-list-header-date">
            <p class="text-header small">Tanggal</p>
          </div>
          <div class="purchases-list-header-details">
            <p class="text-header small">Informasi Tambahan</p>
          </div>
          <div class="purchases-list-header-info">
            <p class="text-header small">Tipe</p>
          </div>
          <div class="purchases-list-header-price">
            <p class="text-header small">Harga</p>
          </div>
          <div class="purchases-list-header-download">
            <p class="text-header small">Status</p>
          </div>
        </div>

        <div class="purchase-item" :key="i" v-for="(items, i) in transaksiList">
          <div class="purchase-item-date">
            <a href="javascript:;" @click.prevent="getDetailTransaksi(items.detail_url)">
              <p class="light2">{{items.tanggal_book}}</p>
            </a>
          </div>
          <div class="purchase-item-details">
            <div class="item-preview" style="padding:0px">
              <a href="javascript:;" @click.prevent="getDetailTransaksi(items.detail_url)">
                <p>
                  <span class="light2">{{items.nama_transaksi}}</span>
                </p>
                <p class="category primary">Kode Booking: {{items.no_transaksi}}</p>
              </a>
            </div>
          </div>
          <div class="purchase-item-info">
            <a href="javascript:;" @click.prevent="getDetailTransaksi(items.detail_url)">
              <p class="light2">{{items.tipe}}</p>
            </a>
          </div>
          <div class="purchase-item-price">
            <a href="javascript:;" @click.prevent="getDetailTransaksi(items.detail_url)">
              <p class="price">
                <span>Rp.</span>
                {{ items.harga | numeralFormat('0,0[.]00') }}
              </p>
            </a>
          </div>
          <div class="purchase-item-download" style="text-align: center">
            <a href="javascript:;" @click.prevent="getDetailTransaksi(items.detail_url)">
              <p class="light2">{{items.status}}</p>
            </a>
          </div>
        </div>
      </div> -->
      <table class="table table-borderless">
        <thead>
          <tr>
            <th class="font-weight-6 font-12 text-default" scope="col">Tanggal</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Informasi Tambahan</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Harga</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Status</th>
          </tr>
        </thead>
        <tbody v-if="restNo == 0">
          <tr :key="i" v-for="(items, i) in transaksiList">
            <td class="font-weight-5 font-12 text-grey" :data-label="'Tanggal'">
              <span class="cursor-pointer" @click.prevent="getDetailTransaksi(items.detail_url)">{{items.tanggal_book}}</span>
            </td>
            <td class="font-weight-5 font-12 text-grey" :data-label="'Invoice'" @click.prevent="getDetailTransaksi(items.detail_url)">
              <span class="cursor-pointer" @click.prevent="getDetailTransaksi(items.detail_url)">
                {{items.nama_transaksi}} <br/> 
                Kode Booking: <span class="text-warning"> {{items.no_transaksi}}</span>
              </span>
            </td>
            <td class="font-weight-5 font-12 text-primary" :data-label="'Harga'">
              <span class="text-warning cursor-pointer" @click.prevent="getDetailTransaksi(items.detail_url)">Rp.
              {{ items.harga | numeralFormat('0,0[.]00') }}</span>
            </td>
            <td class="font-weight-5 font-12 text-primary" :data-label="'Status'">
              <span class="cursor-pointer" @click.prevent="getDetailTransaksi(items.detail_url)">{{items.status}}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="4">Tidak ada transaksi.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-hotel" size="lg" hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <div class="col-lg-12 mb-10">
          <div class="card shadow text-left">
            <iframe id="frame" v-bind:src="urlDetail" width="100%" height="600"></iframe>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
 
<script>
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // flatPickr
  },
  data() {
    return {
      LoginCheck: false,
      transaksiList: [],
      userData: this.$store.state.userdata,
      date: new Date(),
      tipeTrans: null,
      urlDetail: "",
      restNo:null,
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      }
    };
  },
  watch: {},
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getData();
  },

  methods: {
    getData() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "transaction");
      param.append("keyword", "");
      param.append("call", "list_transaction");
      param.append("partner_id", this.userData.partner_id);
      param.append("tipe", 'hotel');
      param.append("page", "1");
      param.append("per_page", "10");
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          this.restNo = response.data.rest_no;
          this.transaksiList = res.data;
        })
        .catch(error => {
          error;
        });
    },
    getDetailTransaksi(val) {
      this.$bvModal.show("modal-hotel");
      this.urlDetail = val;
      console.log(this.urlDetail)
    },
  }
};
</script>