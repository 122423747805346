<template>
  <div class="card shadow text-left pad-10">
    <span
      class="col-lg-12 d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary card-title"
    >Data Transaksi Umroh</span>
    <div class="mt-2">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th class="font-weight-6 font-12 text-default" scope="col">Tanggal</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Informasi Tambahan</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Harga</th>
            <th class="font-weight-6 font-12 text-default" scope="col">Status</th>
          </tr>
        </thead>
        <tbody v-if="transaksiList != ''">
          <tr :key="i" v-for="(items, i) in transaksiList">
            <td class="font-weight-5 font-12 text-grey" :data-label="'Tanggal'">
              <span class="cursor-pointer" @click.prevent="changePage(items)">{{items.created}}</span>
            </td>
            <td class="font-weight-5 font-12 text-grey" :data-label="'Invoice'" @click.prevent="changePage(items)">
              <span class="cursor-pointer" @click.prevent="changePage(items)">
                {{items.name}} <br/> 
                Kode Booking: <span class="text-warning"> {{items.booking_number}}</span>
              </span>
            </td>
            <td class="font-weight-5 font-12 text-primary" :data-label="'Harga'">
              <span class="text-warning cursor-pointer" @click.prevent="changePage(items)">Rp.
              {{ items.room_price | numeralFormat('0,0[.]00') }}</span>
            </td>
            <td class="font-weight-5 font-12 text-primary" :data-label="'Status'">
              <span class="cursor-pointer" @click.prevent="changePage(items)">{{items.status_booking}}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="4">Tidak ada transaksi.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-Success" size="lg" hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <div class="col-lg-12 mb-10">
          <div class="card shadow text-left">
            <iframe id="frame" v-bind:src="urlDetail" width="100%" height="600"></iframe>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
 
<script>
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // flatPickr
  },
  data() {
    return {
      LoginCheck: false,
      transaksiList: [],
      userData: this.$store.state.userdata,
      date: new Date(),
      tipeTrans: null,
      urlDetail: "",
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      packageData: [],
      packageName: {}
    };
  },
  watch: {},
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getData();
  },

  methods: {
    getData() {
      // let param = new FormData();
      // param.append("api_key", "2222");
      // param.append("format", "");
      // param.append("ws", "transaction");
      // param.append("keyword", "");
      // param.append("call", "list_transaction");
      // param.append("partner_id", this.userData.partner_id);
      // param.append("tipe", 'umroh');
      // param.append("page", "1");
      // param.append("per_page", "10");
      // this.axios
      //   .post("https://apitiket.realtravel.co.id/ws", param, {
      //     headers: new Headers({
      //       accept: "application/json",
      //       "Content-Type": "multipart/form-data, text/plain"
      //     })
      //   })
      //   .then(response => {
      //     let res = response.data;
      //     this.transaksiList = res.data;
      //   })
      //   .catch(error => {
      //     error;
      //   });
      this.axios
      .get("https://api.realtravel.co.id/transaction/booking", {
        headers: {
          "token": this.userData.token,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(response => {
        let res = response.data;
        let data = res.response.data;
        for (let i = 0; i < data.length; i++) {
          // this.packageData = this.transaksiList[i].package_data;
          this.transaksiList.push({
            id: data[i].id,
            name: data[i].package_data.name,
            created: data[i].created,
            booking_number: data[i].booking_number,
            room_price: data[i].room_price,
            status_booking:data[i].status_booking
          })
        }
      })
      .catch(error => {
        error;
      });
    },
    getDetailTransaksi(val) {
      this.$bvModal.show("modal-Success");
      this.urlDetail = val;
    },
    changePage(NewVal){
      this.$router.push('transaksi/detail/'+NewVal.id)
    }
  }
};
</script>