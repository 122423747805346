<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="cotainer-standar">
      <div class="container-standar mb-10 pad-20">
        <div class="row">
            <div class="col-md-2 pad-20 card shadow card-sidebar">
              <span class="d-inline-block font-17 pb-4 width-100 text-left text-default">
                  Filtered
              <hr>
              </span>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('umroh')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="@/assets/images/icon/umroh.png" alt /></span>
                  <span class="d-inline-block font-14 pt-3 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentUmroh}">
                  Umroh</span>
              </div>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('haji')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="@/assets/images/icon/umroh.png" alt /></span>
                  <span class="d-inline-block font-14 pt-3 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentHaji}">
                  Haji</span>
              </div>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('visa')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="https://cdn.iconscout.com/icon/free/png-512/visa-3-226460.png" alt /></span>
                  <span class="d-inline-block font-14 pt-4 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentVisa}">
                  </span>
              </div>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('pesawat')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="@/assets/images/icon/pesawat.png" alt /></span>
                  <span class="d-inline-block font-14 pt-3 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentPesawat}">
                  Pesawat</span>
              </div>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('hotel')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="@/assets/images/icon/hotel.png" alt /></span>
                  <span class="d-inline-block font-14 pt-3 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentHotel}">
                  Hotel</span>
              </div>
              <div class="form-group d-inline-block bor-bot-1 cursor-pointer" @click.prevent="onclickSidebar('tour')">
                  <span class="float-left font-weight-4 pt-2 pb-2 width-30 text-primary font-14">
                  <img src="@/assets/images/icon/tour.png" alt /></span>
                  <span class="d-inline-block font-14 pt-3 width-70 text-left text-primary"
                  v-bind:class="{'font-weight-7': showContentTour}">
                  Tour</span>
              </div>
            </div>
            <div class="col-md-12 mt-4 mb-4 sidebar-icon d-none">
              <div class="form-group col-md-12 d-inline-block cursor-pointer">
                <div class="finding-card pb-5 shadow-md border-radius-5">
                  <b-tabs content-class="mt-3" fill>
                    <b-tab @click.prevent="onclickSidebar('umroh')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="@/assets/images/icon/umroh.png" alt />
                          <span>Umroh</span>
                        </div>
                      </template>
                    </b-tab>

                    <b-tab @click.prevent="onclickSidebar('haji')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="@/assets/images/icon/umroh.png" alt />
                          <span>Haji</span>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab @click.prevent="onclickSidebar('visa')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="https://cdn.iconscout.com/icon/free/png-512/visa-3-226460.png" alt />
                          <span>Visa</span>
                        </div>
                      </template>
                    </b-tab>

                    <b-tab @click.prevent="onclickSidebar('pesawat')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="@/assets/images/icon/pesawat.png" alt />
                          <span>Pesawat</span>
                        </div>
                      </template>
                    </b-tab>

                    <b-tab @click.prevent="onclickSidebar('hotel')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="@/assets/images/icon/hotel.png" alt />
                          <span>Hotel</span>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab @click.prevent="onclickSidebar('tour')">
                      <template v-slot:title>
                        <div class="finding-tab">
                          <img src="@/assets/images/icon/tour.png" alt />
                          <span class="text-center">Tour</span>
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-9">
                <div v-show="showContentUmroh" class="col-md-12"><transaksiUmroh></transaksiUmroh></div>
                <div v-show="showContentHaji" class="col-md-12"><transaksiHaji></transaksiHaji></div>
                <div v-show="showContentVisa" class="col-md-12"><transaksiVisa></transaksiVisa></div>
                <div v-show="showContentPesawat" class="col-md-12"><transaksiFlight></transaksiFlight></div>
                <div v-show="showContentHotel" class="col-md-12"><transaksiHotel></transaksiHotel></div>
                <div v-show="showContentTour" class="col-md-12"><transaksiTour></transaksiTour></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/components/_transaksi.scss";
import "flatpickr/dist/flatpickr.css";
import transaksiUmroh from "@/views/components/transaksi/umroh.vue";
import transaksiHaji from "@/views/components/transaksi/haji.vue";
import transaksiVisa from "@/views/components/transaksi/visa.vue";
import transaksiFlight from "@/views/components/transaksi/flight.vue";
import transaksiHotel from "@/views/components/transaksi/hotel.vue";
import transaksiTour from "@/views/components/transaksi/tour.vue";
export default {
  components: {
    transaksiUmroh,
    transaksiHaji,
    transaksiVisa,
    transaksiFlight,
    transaksiHotel,
    transaksiTour,
  },
  data() {
    return {
      LoginCheck: false,
      transaksiList: [],
      userData: this.$store.state.userdata,
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      showContentUmroh:true,
      showContentHaji:false,
      showContentVisa:false,
      showContentPesawat:false,
      showContentHotel:false,
      showContentTour:false
    };
  },
  watch: {},
  mounted() {
    this.$store.commit("SET_BG", false);
  },
  methods: {
    onclickSidebar(val){
        console.log(val)
        if (val == 'umroh') {
            this.showContentUmroh=true; this.showContentHaji=false; this.showContentVisa=false; this.showContentPesawat=false;
            this.showContentHotel=false; this.showContentTour=false;
        } else if (val == 'haji'){
            this.showContentHaji=true; this.showContentUmroh=false; this.showContentVisa=false; this.showContentPesawat=false;
            this.showContentHotel=false; this.showContentTour=false;
        }else if(val == 'visa'){
            this.showContentVisa=true; this.showContentUmroh=false; this.showContentHaji=false; this.showContentPesawat=false;
            this.showContentHotel=false; this.showContentTour=false;
        }else if(val == 'pesawat'){
            this.showContentPesawat=true; this.showContentVisa=false; this.showContentUmroh=false; this.showContentHaji=false;
            this.showContentHotel=false; this.showContentTour=false;
        }else if(val == 'hotel'){
            this.showContentHotel=true; this.showContentPesawat=false; this.showContentVisa=false; this.showContentUmroh=false; 
            this.showContentHaji=false; this.showContentTour=false;
        }else if(val == 'tour'){
            this.showContentTour=true; this.showContentHotel=false; this.showContentPesawat=false; this.showContentVisa=false; 
            this.showContentUmroh=false; this.showContentHaji=false;
        }
    },
  }
};
</script>